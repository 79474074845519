// src/services/api.js

import axios from 'axios';

const API_URL = 'https://ghostedby.club/api'; // Update with your API base URL

// Set up Axios instance with base URL
const api = axios.create({
    baseURL: API_URL,
});

// Add a request interceptor to include the JWT token
api.interceptors.request.use(
    config => {
        const token = localStorage.getItem('token'); // Or use any other storage/session method
        if (token) {
            config.headers.Authorization = `Bearer ${token}`;
        }
        return config;
    },
    error => Promise.reject(error)
);

export default api;
