// src/components/Dashboard.js

import moment from 'moment/moment';
import React, { useContext, useEffect, useState } from 'react';
import { AuthContext } from '../contexts/AuthContext';
import api from '../services/api';

const Profile = () => {
    const { user } = useContext(AuthContext);

    const [data, setData] = useState([]);
    const [totalSubmissions, setTotalSubmissions] = useState(0);
    const [loading, setLoading] = useState(false);
    const [pageIndex, setPageIndex] = useState(0);
    const [pageSize, setPageSize] = useState(10);
    const [imageIndexesToSkip, setImageIndexesToSkip] = useState([]);

    // State for inline editing
    const [editingRowId, setEditingRowId] = useState(null);
    const [editFormData, setEditFormData] = useState({
        note: '',
        rejection_date: '',
    });
    const [searchTerm, setSearchTerm] = useState('');
    const [sortField, setSortField] = useState(''); // e.g., 'company_name', 'submission_count'
    const [sortDirection, setSortDirection] = useState('ASC'); // 'asc' or 'desc'
    const [newCompanyName, setNewCompanyName] = useState('');
    const [newSubmissionDate, setNewSubmissionDate] = useState('');
    
    const totalPages = Math.ceil(totalSubmissions / pageSize);

    // Handle sorting
    const handleSort = (field) => {
        if (sortField === field) {
            // Toggle sorting direction if the same field is clicked
            setSortDirection(sortDirection === 'ASC' ? 'DESC' : 'ASC');
        } else {
            // Set new field for sorting
            setSortField(field);
            setSortDirection('ASC'); // Default to ascending on first click
        }
    };

    const handleSearchChange = (e) => {
        setSearchTerm(e.target.value);
        setPageIndex(0); // Reset to first page when searching
    };

    // Fetch data from the API
    const fetchData = () => {
        setLoading(true);
        setImageIndexesToSkip([]);
        api
            .get('/submissions/user/submissions', {
                params: {
                    page: pageIndex + 1, // Backend expects 1-based page index
                    limit: pageSize,
                    search: searchTerm,
                    sort: sortField,
                    order: sortDirection,
                },
            })
            .then((response) => {
                setData(response.data.data);
                setTotalSubmissions(response.data.total);
                setLoading(false);
            })
            .catch((error) => {
                console.error('Error fetching submissions:', error);
                setLoading(false);
            });
    };

    useEffect(() => {
        fetchData();
    }, [pageIndex, pageSize, searchTerm, sortField, sortDirection]);

    // Handle page change
    const handlePageChange = (newPageIndex) => {
        if (newPageIndex >= 0 && newPageIndex < totalPages) {
            setPageIndex(newPageIndex);
        }
    };

    // Handle page size change
    const handlePageSizeChange = (e) => {
        setPageSize(Number(e.target.value));
        setPageIndex(0); // Reset to first page
    };

    // Handle editing submissions
    const handleEdit = (submission) => {
        const rejectionDate = submission.rejection_date
            ? submission.rejection_date.split('T')[0] // Extract date if necessary
            : '';
        setEditingRowId(submission.id);
        setEditFormData({
            note: submission.note || '',
            rejection_date: rejectionDate,
        });
    };

    // Handle cancel editing
    const handleCancel = () => {
        setEditingRowId(null);
        setEditFormData({
            note: '',
            rejection_date: '',
        });
    };

    // Handle saving edits
    const handleSave = () => {
        const updatedData = {
            note: editFormData.note,
            rejection_date: editFormData.rejection_date || null,
        };

        api
            .put(`/submissions/${editingRowId}`, updatedData)
            .then(() => {
                // Update the data in the data array
                setData((prevData) =>
                    prevData.map((item) =>
                        item.id === editingRowId ? { ...item, ...updatedData } : item
                    )
                );
                setEditingRowId(null);
                setEditFormData({
                    note: '',
                    rejection_date: '',
                });
            })
            .catch((error) => {
                console.error('Error updating submission:', error);
            });
    };

    // Handle input changes
    const handleInputChange = (e) => {
        const { name, value } = e.target;
        setEditFormData((prevData) => ({
            ...prevData,
            [name]: value,
        }));
    };

    // Handle deleting submissions
    const handleDelete = (submission) => {
        if (window.confirm('Are you sure you want to delete this submission?')) {
            api
                .delete(`/submissions/${submission.id}`)
                .then(() => {
                    // Remove the deleted submission from the data array
                    setData((prevData) =>
                        prevData.filter((item) => item.id !== submission.id)
                    );
                    setTotalSubmissions((prevTotal) => prevTotal - 1);
                })
                .catch((error) => {
                    console.error('Error deleting submission:', error);
                });
        }
    };

    const handleNewCompanyChange = (e) => {
        setNewCompanyName(e.target.value);
    };

    const handleNewSubmissionDateChange = (e) => {
        setNewSubmissionDate(e.target.value);
    };

    const handleAddSubmission = (e) => {
        e.preventDefault();        
        api.post('/submissions', {
            company_name: newCompanyName,
            submission_date: moment(newSubmissionDate).format(),
        }).then(() => {
            fetchData();
            setNewCompanyName('');
            setNewSubmissionDate('');
        }).catch((error) => {
            console.error('Error submitting:', error);
        });            
    };

    return (
        <div className="container mx-auto px-4 py-6">
            <div className="my-2 w-full flex justify-between">
                <h2 className="text-2xl font-bold mb-4">Personal Dashboard</h2>
                <p className="text-lg">Your Applications: {totalSubmissions}</p>
            </div>
            <div className="mb-6">
                <h3 className="text-xl font-bold mb-4">Add New Submission</h3>
                <form onSubmit={handleAddSubmission} className="flex flex-col space-y-4">
                    <input
                        type="text"
                        value={newCompanyName}
                        onChange={handleNewCompanyChange}
                        placeholder="Company Name"
                        required
                        className="border p-2 rounded w-full"
                    />
                    <input
                        type="date"
                        value={newSubmissionDate}
                        onChange={handleNewSubmissionDateChange}
                        placeholder="Submission Date"
                        required
                        className="border p-2 rounded w-full"
                    />
                    <button type="submit" className="bg-blue-500 text-white px-4 py-2 rounded">
                        Add Submission
                    </button>
                </form>
            </div>
            <div className="mb-6">
                <input
                    type="text"
                    value={searchTerm}
                    onChange={handleSearchChange}
                    placeholder="Search by company name..."
                    className="border p-2 rounded w-full"
                />
            </div>
            <div className="overflow-x-auto">
                <table className="min-w-full bg-white shadow rounded">
                    <thead>
                        <tr className="bg-gray-100">
                            <th className="text-left px-4 py-2">Logo</th>
                            <th className="text-left px-4 py-2 cursor-pointer" onClick={() => handleSort('company_name')}>
                                Company Name {sortField === 'company_name' && sortDirection === 'ASC' ? '▲' : '▼'}
                            </th>
                            <th className="text-left px-4 py-2 cursor-pointer" onClick={() => handleSort('submission_date')}>
                                Submission Date {sortField === 'submission_date' && sortDirection === 'ASC' ? '▲' : '▼'}
                            </th>
                            <th className="text-left px-4 py-2 cursor-pointer" onClick={() => handleSort('rejection_date')}>
                                Rejection Date {sortField === 'rejection_date' && sortDirection === 'ASC' ? '▲' : '▼'}
                            </th>
                            <th className="text-left px-4 py-2">Note</th>
                            <th className="text-left px-4 py-2">Actions</th>
                        </tr>
                    </thead>
                    <tbody>
                        {loading ? (
                            <tr>
                                <td colSpan="6" className="text-center py-4">
                                    Loading...
                                </td>
                            </tr>
                        ) : data?.length > 0 ? (
                            data.map((submission, index) => (
                                <tr
                                    key={submission.id}
                                    className={index % 2 === 0 ? 'bg-white' : 'bg-gray-50'}
                                >
                                    <td className="px-4 py-2">
                                        {imageIndexesToSkip.includes(index) ? null : (
                                            <img
                                                src={`https://logo.clearbit.com/${encodeURIComponent(
                                                    submission.company_name
                                                )}.com`}
                                                alt={`${submission.company_name} logo`}
                                                className="h-8 w-8 object-contain"
                                                onError={() => {
                                                    setImageIndexesToSkip([
                                                        ...imageIndexesToSkip,
                                                        index,
                                                    ]);
                                                }}
                                            />
                                        )}
                                    </td>
                                    <td className="px-4 py-2">{submission.company_name}</td>
                                    <td className="px-4 py-2">{moment(submission.submission_date).format('DD MMM YYYY')}</td>
                                    <td className="px-4 py-2">
                                        {editingRowId === submission.id ? (
                                            <input
                                                type="date"
                                                name="rejection_date"
                                                value={editFormData.rejection_date}
                                                onChange={handleInputChange}
                                                className="border p-1 rounded"
                                            />
                                        ) : submission.rejection_date ? (
                                            moment(submission.rejection_date).format('DD MMM YYYY')
                                        ) : (
                                            ''
                                        )}
                                    </td>
                                    <td className="px-4 py-2">
                                        {editingRowId === submission.id ? (
                                            <input
                                                type="text"
                                                name="note"
                                                value={editFormData.note}
                                                onChange={handleInputChange}
                                                className="border p-1 rounded w-full"
                                            />
                                        ) : (
                                            submission.note
                                        )}
                                    </td>
                                    <td className="px-4 py-2">
                                        <div className="flex space-x-2">
                                            {editingRowId === submission.id ? (
                                                <>
                                                    <button
                                                        onClick={handleSave}
                                                        className="text-green-500 hover:underline"
                                                    >
                                                        Save
                                                    </button>
                                                    <button
                                                        onClick={handleCancel}
                                                        className="text-gray-500 hover:underline"
                                                    >
                                                        Cancel
                                                    </button>
                                                </>
                                            ) : (
                                                <>
                                                    <button
                                                        onClick={() => handleEdit(submission)}
                                                        className="text-blue-500 hover:underline"
                                                    >
                                                        Edit
                                                    </button>
                                                    <button
                                                        onClick={() => handleDelete(submission)}
                                                        className="text-red-500 hover:underline"
                                                    >
                                                        Delete
                                                    </button>
                                                </>
                                            )}
                                        </div>
                                    </td>
                                </tr>
                            ))
                        ) : (
                            <tr>
                                <td colSpan="6" className="text-center py-4">
                                    No data found.
                                </td>
                            </tr>
                        )}
                    </tbody>
                </table>
            </div>

            {/* Pagination Controls */}
            <div className="flex items-center justify-between mt-4">
                <div>
                    <button
                        onClick={() => handlePageChange(0)}
                        disabled={pageIndex === 0}
                        className="mr-2 px-2 py-1 bg-gray-200 rounded disabled:opacity-50"
                    >
                        {'<<'}
                    </button>
                    <button
                        onClick={() => handlePageChange(pageIndex - 1)}
                        disabled={pageIndex === 0}
                        className="mr-2 px-2 py-1 bg-gray-200 rounded disabled:opacity-50"
                    >
                        {'<'}
                    </button>
                    <button
                        onClick={() => handlePageChange(pageIndex + 1)}
                        disabled={pageIndex >= totalPages - 1}
                        className="mr-2 px-2 py-1 bg-gray-200 rounded disabled:opacity-50"
                    >
                        {'>'}
                    </button>
                    <button
                        onClick={() => handlePageChange(totalPages - 1)}
                        disabled={pageIndex >= totalPages - 1}
                        className="px-2 py-1 bg-gray-200 rounded disabled:opacity-50"
                    >
                        {'>>'}
                    </button>
                </div>
                <div>
                    Page{' '}
                    <strong>
                        {pageIndex + 1} of {totalPages}
                    </strong>
                </div>
                <div>
                    <select
                        value={pageSize}
                        onChange={handlePageSizeChange}
                        className="border p-1 rounded"
                    >
                        {[10, 20, 30, 40, 50].map((size) => (
                            <option key={size} value={size}>
                                Show {size}
                            </option>
                        ))}
                    </select>
                </div>
            </div>
        </div>
    );
};

export default Profile;
