import { GoogleOAuthProvider } from '@react-oauth/google';
import React from 'react';
import ReactDOM from 'react-dom';
import { BrowserRouter } from 'react-router-dom';
import App from './App';
import { AuthProvider } from './contexts/AuthContext';
import './index.css';

ReactDOM.render(
	<BrowserRouter>
		<GoogleOAuthProvider clientId="124178155630-uheq43i2n0fb8tnhkvpq1qlc79070nhk.apps.googleusercontent.com">
			<AuthProvider>
				<App />
			</AuthProvider>
		</GoogleOAuthProvider>
	</BrowserRouter>,
	document.getElementById('root')
);
