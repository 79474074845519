// src/components/Footer.js
import React from 'react';

const Footer = () => {
    return (
        <footer className="text-center py-4 bg-gray-800 text-white shadow-md w-full">
            <p>
                Built by{' '}
                <a href="https://x.com/k_ivanow" target="_blank" rel="noopener noreferrer">
                    https://x.com/k_ivanow
                </a>
            </p>
        </footer>
    );
};

export default Footer;
