// src/components/Login.js

import { GoogleLogin } from '@react-oauth/google';
import React, { useContext } from 'react';
import { useNavigate } from 'react-router-dom';
import { AuthContext } from '../contexts/AuthContext';
import api from '../services/api';

const Login = () => {
    const { login } = useContext(AuthContext);
    const navigate = useNavigate();

    const handleSuccess = credentialResponse => {
        const id_token = credentialResponse.credential;
        // Send the token to your backend
        api
            .post('/auth/google', { id_token })
            .then(response => {
                login(response.data.token);
                navigate('/');
            })
            .catch(error => {
                console.error('Login failed:', error);
            });
    };

    const handleError = () => {
        console.error('Login failed');
    };

    return (
        <div className="flex flex-col items-center mt-8">
            <h2 className="text-2xl font-bold mb-4">Login</h2>
            <GoogleLogin onSuccess={handleSuccess} onError={handleError} />
        </div>
    );
};

export default Login;
