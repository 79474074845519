import React, { useContext } from 'react';
import { Link } from 'react-router-dom';
import { AuthContext } from '../contexts/AuthContext';

const Navbar = () => {
    const { user, logout } = useContext(AuthContext);

    return (
        <nav className="bg-gray-800 text-white shadow-md w-full flex justify-between items-center px-6 py-4">
            <div className="flex items-center space-x-4">
                <Link to="/" className="text-lg font-semibold hover:text-gray-300">
                    Dashboard
                </Link>
            </div>
            <div className="flex items-center space-x-6">
                {user ? (
                    <>
                        <Link to="/profile" className="hover:text-gray-300">
                            My Profile
                        </Link>
                        <button
                            onClick={logout}
                            className="bg-red-600 hover:bg-red-700 text-white py-1 px-4 rounded-md"
                        >
                            Logout
                        </button>
                    </>
                ) : (
                    <Link
                        to="/login"
                        className="bg-blue-600 hover:bg-blue-700 text-white py-1 px-4 rounded-md"
                    >
                        Login
                    </Link>
                )}
            </div>
        </nav>
    );
};

export default Navbar;
