import moment from 'moment/moment';
import React, { useEffect, useState } from 'react';
import api from '../services/api';

const Dashboard = () => {
    const [data, setData] = useState([]);
    const [totalSubmissions, setTotalSubmissions] = useState(0);
    const [loading, setLoading] = useState(false);
    const [pageIndex, setPageIndex] = useState(0);
    const [pageSize, setPageSize] = useState(10);
    const [imageIndexesToSkip, setImageIndexesToSkip] = useState([]);

    const [searchTerm, setSearchTerm] = useState('');
    const [sortField, setSortField] = useState(''); 
    const [sortDirection, setSortDirection] = useState('ASC'); 
    
    
    const totalPages = Math.ceil(totalSubmissions / pageSize);

    const fetchData = () => {
        setLoading(true);
        setImageIndexesToSkip([]);
        api.get('/submissions', {
            params: {
                page: pageIndex + 1,
                limit: pageSize,
                search: searchTerm,
                sort: sortField,
                order: sortDirection,
            },
        }).then((response) => {
            setData(response.data.data);
            setTotalSubmissions(response.data.total);
            setLoading(false);
        }).catch((error) => {
            console.error('Error fetching submissions:', error);
            setLoading(false);
        });
    };

    useEffect(() => {
        fetchData();
    }, [pageIndex, pageSize, searchTerm, sortField, sortDirection]);

    const handleSearchChange = (e) => {
        setSearchTerm(e.target.value);
        setPageIndex(0);
    };

    const handleSort = (field) => {
        if (sortField === field) {
            setSortDirection(sortDirection === 'ASC' ? 'DESC' : 'ASC');
        } else {
            setSortField(field);
            setSortDirection('ASC');
        }
    };

    const handlePageChange = (newPageIndex) => {
        if (newPageIndex >= 0 && newPageIndex < totalPages) {
            setPageIndex(newPageIndex);
        }
    };

    const handlePageSizeChange = (e) => {
        setPageSize(Number(e.target.value));
        setPageIndex(0);
    };

    return (
        <div className="container mx-auto px-4 py-6">
                <h1 class="text-2xl sm:text-3xl md:text-4xl font-bold text-center">
                    Are you tired of companies never replying?
                </h1>
                <h3 class="text-1xl sm:text-2xl md:text-3xl font-bold text-center">
                    See which of them ghost the most before applying.
                </h3>
                <br />
                <h5 class="text-center text-sm">
                    Signin to contribute by sharing when you applied to a company and never heard back. Or when you applied to only hear back after 6 months time.
                </h5>
                <h5 class="text-center text-sm">
                    You can also use it just to keep track of when and where you applied to.
                </h5>

            <div className="my-2 w-full flex justify-between">
                <h2 className="text-1xl font-bold">Dashboard</h2>
                <div className="text-sm">Total Reports: {totalSubmissions}</div>
            </div>   


            <div className="mb-2">
                <input
                    type="text"
                    value={searchTerm}
                    onChange={handleSearchChange}
                    placeholder="Search by company name..."
                    className="border p-2 rounded w-full"
                />
            </div>

            <div className="overflow-x-auto">
                <table className="min-w-full bg-white shadow rounded">
                    <thead>
                        <tr className="bg-gray-100">
                            <th className="text-left px-4 py-2">Logo</th>
                            <th className="text-left px-4 py-2 cursor-pointer" onClick={() => handleSort('company_name')}>
                                Company Name {sortField === 'company_name' && sortDirection === 'ASC' ? '▲' : '▼'}
                            </th>
                            <th className="text-left px-4 py-2 cursor-pointer" onClick={() => handleSort('submission_count')}>
                                Reports {sortField === 'submission_count' && sortDirection === 'ASC' ? '▲' : '▼'}
                            </th>
                            <th className="text-left px-4 py-2 cursor-pointer" onClick={() => handleSort('first_submission')}>
                                First Submission {sortField === 'first_submission' && sortDirection === 'ASC' ? '▲' : '▼'}
                            </th>
                            <th className="text-left px-4 py-2 cursor-pointer" onClick={() => handleSort('last_submission')}>
                                Last Submission {sortField === 'last_submission' && sortDirection === 'ASC' ? '▲' : '▼'}
                            </th>
                            <th className="text-left px-4 py-2 cursor-pointer" onClick={() => handleSort('average_rejection_time')}>
                                Average reply time {sortField === 'average_rejection_time' && sortDirection === 'ASC' ? '▲' : '▼'}
                            </th>
                        </tr>
                    </thead>
                    <tbody>
                        {loading ? (
                            <tr>
                                <td colSpan="6" className="text-center py-4">
                                    Loading...
                                </td>
                            </tr>
                        ) : data?.length > 0 ? (
                            data.map((submission, index) => (
                                <tr
                                    key={submission.id}
                                    className={index % 2 === 0 ? 'bg-white' : 'bg-gray-50'}
                                >
                                    <td className="px-4 py-2">
                                        {imageIndexesToSkip.includes(index) ? null : (
                                            <img
                                                src={`https://logo.clearbit.com/${encodeURIComponent(
                                                    submission.company_name
                                                )}.com`}
                                                alt={`${submission.company_name} logo`}
                                                className="h-8 w-8 object-contain"
                                                onError={() => {
                                                    setImageIndexesToSkip([
                                                        ...imageIndexesToSkip,
                                                        index,
                                                    ]);
                                                }}
                                            />
                                        )}
                                    </td>
                                    <td className="px-4 py-2">{submission.company_name}</td>
                                    <td className="px-4 py-2">{parseInt(submission.submission_count)}</td>
                                    <td className="px-4 py-2">{moment(submission.first_submission).format('DD MMM YYYY')}</td>
                                    <td className="px-4 py-2">{moment(submission.last_submission).format('DD MMM YYYY')}</td>
                                    <td className="px-4 py-2">{!!parseInt(submission.average_rejection_time) ? `${parseInt(submission.average_rejection_time)} Days` : 'N/A'}</td>
                                </tr>
                            ))
                        ) : (
                            <tr>
                                <td colSpan="6" className="text-center py-4">
                                    No data found.
                                </td>
                            </tr>
                        )}
                    </tbody>
                </table>
            </div>

            <div className="flex items-center justify-between mt-4">
                <div>
                    <button
                        onClick={() => handlePageChange(0)}
                        disabled={pageIndex === 0}
                        className="mr-2 px-2 py-1 bg-gray-200 rounded disabled:opacity-50"
                    >
                        {'<<'}
                    </button>
                    <button
                        onClick={() => handlePageChange(pageIndex - 1)}
                        disabled={pageIndex === 0}
                        className="mr-2 px-2 py-1 bg-gray-200 rounded disabled:opacity-50"
                    >
                        {'<'}
                    </button>
                    <button
                        onClick={() => handlePageChange(pageIndex + 1)}
                        disabled={pageIndex >= totalPages - 1}
                        className="mr-2 px-2 py-1 bg-gray-200 rounded disabled:opacity-50"
                    >
                        {'>'}
                    </button>
                    <button
                        onClick={() => handlePageChange(totalPages - 1)}
                        disabled={pageIndex >= totalPages - 1}
                        className="px-2 py-1 bg-gray-200 rounded disabled:opacity-50"
                    >
                        {'>>'}
                    </button>
                </div>
                <div>
                    Page{' '}
                    <strong>
                        {pageIndex + 1} of {totalPages}
                    </strong>
                </div>
                <div>
                    <select
                        value={pageSize}
                        onChange={handlePageSizeChange}
                        className="border p-1 rounded"
                    >
                        {[10, 20, 30, 40, 50].map((size) => (
                            <option key={size} value={size}>
                                Show {size}
                            </option>
                        ))}
                    </select>
                </div>
            </div>
        </div>
    );
};

export default Dashboard;

